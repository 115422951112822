import { useFetch } from '@/api';
import { useNotifications } from '@/composables/useNotifications';
import { emitter } from '@/composables/useBus';
import { getLocalizedErrorMessage } from '@/composables/useFetch/helpers';

export const flushPayment = async (params) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { isSuccess, error, data } = await axiosWrapper({
    type: 'post',
    url: '/merchant-admin/flush',
    params,
    isErrorNotification: false,
  });

  const isError = !isSuccess || [data.ETH_ETH, data.ETH_USDC, data.ETH_USDT].some((item) => typeof item === 'string');

  if (isError) {
    const { addNotification } = useNotifications();
    const errorMessage = error?.data?.message || data.ETH_ETH || data.ETH_USDC || data.ETH_USDT;
    const localizedErrorMessage = getLocalizedErrorMessage(errorMessage);

    const color = 'warning';
    addNotification({ text: localizedErrorMessage, config: { color } });
  } else {
    successHandler('Flush successful');
    emitter.emit('update-eth_flush-page');
  }

  return { isSuccess: !isError, error };
};
