<template>
  <DropdownBlock type="bordered" height="auto" style="margin-bottom: 16px;">
    <template #header>
      <div class="header__wrapper">
        <div class="header__wrapper--top">
          <div class="sequence">
            <AppText size="16px" class="font-medium">
              #{{ item.seq }}
            </AppText>
          </div>
          <div class="d-flex flex-column justify-content-between" style="padding: 4px 0;">
            <AppText class="font-medium">
              Flushed on <DateFormatter size="13px" :format="dateTimeFormat" class="font-medium" :date="item.createdAt" />
            </AppText>
            <TooltipCopy :text="item.uuid" @click.stop="() => {}">
              <AppText
                variant="div"
                size="12px"
                opacity="0.4"
                :line-height="1.3"
                style="line-break: anywhere;"
              >
                UUID: {{ item.uuid }}
              </AppText>
            </TooltipCopy>
          </div>
          <div v-for="(val, i) in itemTotal" :key="i" class="amount">
            <AppText class="font-medium">
              {{ val.value }} {{ val.coin }}
            </AppText>
            <CurrencyFormatter
              :value="val.usdValue"
              :format="numberFormat"
            />
          </div>
        </div>
        <AppText class="memo" variant="div" color="var(--color-black-06)" :line-height="1.5">
          {{ item.memo }}
        </AppText>
      </div>
    </template>

    <div class="details">
      <AppText size="11px" color="var(--color-black-04)" class="details-title font-semibold">
        DETAILS
      </AppText>
      <div class="details-data">
        <AppText>Merchant UID:</AppText>
        <TooltipCopy is-icon-hidden :text="item.merchantUid">
          <div class="uid-block">
            <AppIcon name="uid-circle" size="18px" is-img-tag />
            <AppText size="11px" class="uid-block--text font-medium">
              {{ item.merchantUid }}
            </AppText>
          </div>
        </TooltipCopy>
        <template v-for="elem in data" :key="elem.title">
          <template v-if="elem.value">
            <AppText>{{ elem.text }}:</AppText>
            <div>
              <TooltipCopy>
                <AppText :line-height="1.3">
                  {{ elem.value?.hash || elem.value }}
                </AppText>
              </TooltipCopy>
            </div>
          </template>
        </template>
      </div>
    </div>
  </DropdownBlock>
</template>

<script setup>
import { computed } from 'vue';

import DropdownBlock from '@/components/Containers/DropdownBlock.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

import { useAdmin } from '@/composables/useAdmin';
import { useProfile } from '@/composables/useProfile';

const { numberFormat } = useAdmin();
const { dateTimeFormat } = useProfile();

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const data = computed(() => [
  {
    text: 'Payment Address',
    value: props.item.paymentAddress,
  },
  {
    text: 'Target Address',
    value: props.item.destinationAddress,
  },
  {
    text: 'Master Contract',
    value: props.item.masterContractAddress,
  },
  {
    text: 'Transaction Hash (ETH)',
    value: props.item.hashes?.eth,
  },
  {
    text: 'Transaction Hash (USDT)',
    value: props.item.hashes?.usdt,
  },
  {
    text: 'Transaction Hash (USDC)',
    value: props.item.hashes?.usdc,
  },
  {
    text: 'Transaction Hash (DAI)',
    value: props.item.hashes?.dai,
  },
  {
    text: 'Transaction Hash (FDUSD)',
    value: props.item.hashes?.fdusd,
  },
]);

const itemTotal = computed(() => Object.keys(props.item.values).reduce((acc, key) => {
  if (props.item.values[key] !== 0 || props.item.usdValues[key] !== 0) {
    acc.push({
      coin: key.toUpperCase(),
      value: props.item.values[key],
      usdValue: props.item.usdValues[key],
    });
  }
  return acc;
}, []));
</script>

<style scoped lang="scss">
.header__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  .header__wrapper--top {
    display: flex;
    margin-bottom: 20px;
    height: 50px;
    width: 100%;
    padding-right: 40px;
  }
}

.sequence {
  width: 60px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 20px;

  border-radius: 4px;
  background: var(--color-F7F7F7);

  @include flex-center;
}

.amount {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 0;
  margin-left: 90px;

  :deep(.currency) {
    > span {
      color: var(--color-black-04);
    }
  }
}
.memo {
  padding: 6px 12px;
  border-radius: 4px;
  background: var(--color-F7F7F7);
  width: 93%;
}

.details {
  padding: 0 10px;
  &-title {
    display: block;
    margin-bottom: 6px;
  }
}

.details-data {
  display: grid;
  grid-template-columns: minmax(0, max-content) 1fr;
  gap: 10px 30px;
  margin-top: 16px;
}

.uid-block {
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  background: var(--color-F3F3F3);
  padding: 0 10px 0 1px;

  &--text {
    margin-left: 6px;
    letter-spacing: 2.2px;
  }
}
</style>
