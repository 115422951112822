<template>
  <TransitionWrapper>
    <FlushForm :family="family" />
    <FlushHistory :family="family" />
  </TransitionWrapper>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';

import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import FlushForm from './FlushForm.vue';
import FlushHistory from './FlushHistory.vue';

const route = useRoute();
const family = computed(() => route.meta?.family);
</script>
