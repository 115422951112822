<template>
  <div class="flush__history">
    <AppText size="20px" class="font-medium">
      History
    </AppText>

    <div style="margin-top: 20px;">
      <div class="d-flex">
        <SearchInput
          v-model="search"
          :readonly="!isLoaded"
          class="header-search-input"
          :placeholder="t('component.table.search')"
          @submit="onSearch"
        />
        <FilterSelect
          v-model="filter.modelValue"
          :has-close-icon="false"
          :readonly="!isLoaded"
          :filter="filter"
          @update:model-value="onOrderChange"
        />
      </div>

      <template v-if="isLoaded && !items.length">
        <NoData class="no-data" />
      </template>
      <template v-else>
        <template v-for="item in items" :key="item._id">
          <FlushHistoryItem :item="item" />
        </template>
      </template>

      <div class="d-flex">
        <TablePagination
          v-if="isPaginationVisible"
          v-model:page="page"
          :page-count="pageCount"
          :max-items="6"
          class="wrapper-pagination"
          @change-page="onChangePage"
        />
        <FSpacer />
        <ItemsLength
          :page="page"
          :length="items.length"
          :page-count="pageCount"
          :per-page="perPage"
          :total-count="totalCount"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, reactive, watch,
} from 'vue';

import SearchInput from '@/components/Modules/DataTable/SearchInput.vue';
import FilterSelect from '@/components/Modules/DataTable/FilterSelect.vue';
import TablePagination from '@/components/Modules/DataTable/TablePagination.vue';
import ItemsLength from '@/components/Modules/DataTable/ItemsLength.vue';
import NoData from '@/components/MainTable/NoData.vue';

import { updateTable, useTablesStore } from '@/store';
import { MODULES_NAMES } from '@/common/modules';
import { usePagination } from '@/composables/usePagination';

import FlushHistoryItem from './FlushHistoryItem.vue';

const props = defineProps({
  family: {
    type: String,
    default: '',
  },
});

const moduleName = MODULES_NAMES.ADMIN_FLUSH;
const apiPath = '/merchant-admin/flush/records';

const {
  search,
  page,
  items,
  perPage,
  pageCount,
  totalCount,
  isLoaded,
  getData,
} = usePagination(apiPath, {
  eventName: 'update-eth_flush-page',
  moduleName,
});

const tableStore = useTablesStore();
const currentModule = tableStore.getCurrentTable({ moduleName });

const filter = reactive(
  {
    label: '',
    field: 'orderBy',
    options: [
      { text: 'Newest to oldest', value: 'desc' },
      { text: 'Oldest to newest', value: 'asc' },
    ],
    hidePrefix: true,
    modelValue: ref(currentModule?.orderBy || 'desc'),
  },
);
const filters = reactive({
  orderBy: currentModule?.orderBy || 'desc',
  family: props.family,
});

perPage.value = 5;

getData({ filters });

const isPaginationVisible = computed(() => items.value.length && pageCount.value >= 2);

const onSearch = () => {
  getData();
};

const onChangePage = (newPage) => {
  if (Number(newPage) !== 0) {
    getData({ page: newPage });
  }
};

const onOrderChange = (newValue) => {
  filters[filter.field] = newValue;
  updateTable({
    moduleName,
    field: filter.field,
    value: newValue,
  });
  getData({ filters });
};

watch(() => props.family, () => {
  filters.family = props.family;
  getData({ filters, isFreshResponse: true });
});
</script>

<style scoped lang="scss">
.flush__history {
  padding: 40px;
  max-width: 1120px;
  min-height: 300px;
  height: 100%;
}

.header-search-input {
  margin-bottom: 20px;
  flex: 1;
  margin-right: 10px;
}

.no-data {
  width: 100%;
  min-height: 600px;
  border: 1px solid var(--color-black-007);
  border-radius: 4px;
  display: block;
  height: 100%;

  @include flex-center;
}
</style>
