<template>
  <AppTooltip
    class="tooltip-copy"
    :class="{
      'is-inlined': isInlined,
      'is-table-inlined': isTableInlined,
    }"
    position="bottom"
    text-align="center"
    :is-enabled="isEnabled"
    @click="startAction"
  >
    <div
      class="d-flex align-items-center justify-content-center main-text"
      tabindex="0"
      :class="{
        'blink-text': actionStatus,
        hover: isHoverable && !actionStatus,
        'w-100': !isMinGap,
      }"
      @keypress.space="onSpacePress"
      @mouseleave="onMouseOut"
    >
      <div
        ref="copySlot"
        class="d-flex align-items-center main-text-wrapper"
        :class="{
          'has-icon': !isIconHidden,
          'flex-grow-1': !isMinGap,
        }"
      >
        <slot>
          <AppText size="16px">
            {{ text }}
          </AppText>
        </slot>
      </div>
      <AppIcon
        v-if="!isIconHidden"
        class="copy-icon d-flex align-items-center"
        :class="{ 'blink-filled-icon': actionStatus }"
        name="copy"
        size="18px"
        opacity="0.4"
      />
    </div>
    <template #text>
      {{ tooltipText || copyText }}
    </template>
  </AppTooltip>
</template>

<script setup>
import { ref } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { i18n } from '@/plugins/localization';
import { copyTextToClipboard } from '@/utils/functions';

const props = defineProps({
  text: {
    type: [String, Number],
    default: '',
  },
  tooltipText: {
    type: String,
    default: '',
  },
  isMinGap: {
    type: Boolean,
    default: false,
  },
  isIconHidden: {
    type: Boolean,
    default: false,
  },
  isHoverable: {
    type: Boolean,
    default: false,
  },
  isInlined: {
    type: Boolean,
    default: false,
  },
  isTableInlined: {
    type: Boolean,
    default: false,
  },
  isEnabled: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['copy:start', 'copy:end']);

const { t } = i18n.global;
const actionStatus = ref(false);

const copyText = ref(t('common.copy'));
const copySlot = ref(null);

const copyToClipboard = () => {
  const textFromProps = String(props.text) ? String(props.text) : '';
  const textFromSlot = copySlot.value ? copySlot.value.innerText : '';
  const text = textFromProps || textFromSlot;

  copyTextToClipboard(text);
};

const startAction = () => {
  emit('copy:start');
  copyToClipboard();
  actionStatus.value = true;
  copyText.value = t('common.copied');

  setTimeout(() => {
    emit('copy:end');
    actionStatus.value = false;
  }, 250);

  setTimeout(() => {
    copyText.value = t('common.copy');
  }, 1250);
};

const onMouseOut = () => {
  copyText.value = t('common.copy');
};

const onSpacePress = (e) => {
  e.preventDefault();
  startAction();
};
</script>

<style lang="scss" scoped>
.has-icon {
  padding-right: 8px;
}

.tooltip-copy {
  :deep(.tooltip-activator){
    &:hover {
      cursor: pointer;
    }
  }

  .hover:hover{
    :deep(.text) {
      color: var(--color-primary);
      opacity: 1;
    }

    .copy-icon {
      opacity: 1;
      :deep(path) {
        fill: var(--color-primary);
      }
    }
  }

  &.is-inlined {
    display: inline-block;
  }

  &.is-table-inlined {
    display: inline;
    .main-text {
      display: inline;
      .main-text-wrapper {
        display: inline;
      }
    }
  }
}

.main-text {
  @include focus-visible;
}
</style>
