<template>
  <div class="flush__form">
    <ActionForm
      class="action-form"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onValidate"
    >
      <template #default="{ errors, action }">
        <div class="header d-flex align-items-center">
          <AppIcon name="tornado" size="18px" is-img-tag style="margin-right: 13px;" />
          <AppText color="var(--color-black-06)">
            Perform the <span class="font-semibold">flushForwarderTokens</span> function
          </AppText>
        </div>
        <FInput
          v-model="requestFields.uid"
          has-prefix
          :is-inherit-attrs="false"
          mask="onlyInteger"
          placeholder="Merchant UID"
          :maxlength="7"
          size="small"
          :validation-error="errors.uid"
        >
          <template #prefix>
            <AppIcon name="uid-circle" size="18px" is-img-tag />
          </template>
        </FInput>
        <FInput
          v-model="requestFields.uuid"
          placeholder="Payment UUID"
          :is-inherit-attrs="false"
          :maxlength="40"
          size="small"
          :validation-error="errors.uuid"
          style="margin-top: 10px;"
        />
        <FInput
          v-model="requestFields.memo"
          class="memo"
          type="textarea"
          :is-inherit-attrs="false"
          placeholder="Memo"
          maxlength="120"
          :validation-error="errors.memo"
          style="margin-top: 10px;"
        />

        <FButton
          :loading="isLoading"
          size="x-small"
          type="black"
          style="margin-top: 20px;"
          @click="action"
        >
          Attempt flush
        </FButton>
      </template>
    </ActionForm>
    <div class="separator" />
    <div class="flush__caution">
      <AppText
        type="uppercase"
        color="var(--color-black-04)"
        class="font-medium"
        style="margin-bottom: 20px;"
      >
        CAUTION
      </AppText>
      <AppText style="margin-bottom: 20px;" :line-height="1.5">
        This action will fail if there is no gas to cover the transaction cost
      </AppText>
      <AppText style="margin-bottom: 20px;" :line-height="1.5">
        Same payment address can be flushed multiple times in case new funds appear
      </AppText>
      <AppText :line-height="1.5">
        Target address associated with the payment will be the {{ family === 'TRON' ? 'TVM' : 'EVM' }} address currently configured by the merchant.
      </AppText>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import { ActionForm, useValidation, yup } from '@/validation';
import { uidAsSuperadmin } from '@/validation/rules';

import { flushPayment } from './api';

const { initState, fieldsErrors } = useValidation();

defineProps({
  family: {
    type: String,
    default: '',
  },
});

const fields = {
  uid: '',
  uuid: '',
  memo: '',
};

const { requestFields } = initState(fields);

const validationSchema = yup.object().shape({
  uid: uidAsSuperadmin,
  uuid: yup
    .string()
    .required('isRequired'),
  memo: yup
    .string()
    .required('isRequired')
    .min(30, 'notLessThan')
    .max(100, 'notMoreThan'),
});

const isLoading = ref(false);
const onValidate = async () => {
  isLoading.value = true;
  const { isSuccess } = await flushPayment({
    uid: requestFields.uid,
    uuid: requestFields.uuid,
    memo: requestFields.memo,
  });

  if (isSuccess) {
    requestFields.uid = '';
    requestFields.uuid = '';
    requestFields.memo = '';
  }
  isLoading.value = false;
};
</script>

<style scoped lang="scss">
.flush__form {
  padding: 20px 40px 30px;
  background: var(--color-black-003);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;

  @include mq('tablet') {
    flex-direction: column;
    .separator {
      height: 1px;
    }
  }
}

.action-form {
  flex: 1;
  max-width: 430px;
  margin-right: 40px;
  .header {
    margin-bottom: 18px;
  }
}

.separator {
  border: 1px solid var(--color-black-005);
  align-self: stretch;
  height: 190px;
}

.flush__caution {
  flex: 1;
  max-width: 330px;
  display: flex;
  flex-direction: column;
}
</style>
